@media print {
  a {
    color: #000 !important;
    text-decoration: none !important; }
  body {
    font-family: Helvetica, Arial, sans-serif;
    padding: 2em .5cm; }
  main {
    padding-top: 0; }
  img {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%); }
  section .module {
    margin: 1cm 0 !important; }
  .col {
    flex: 0 0 100%;
    max-width: 100%; }
  .serif {
    font-family: Helvetica, Arial, sans-serif; }
  .sans-serif {
    font-family: Helvetica, Arial, sans-serif; }
  h1 {
    font-size: 3rem;
    margin-top: 0 !important;
    margin-bottom: .5cm !important;
    font-weight: bold; }
  h2 {
    margin: .5cm 0; }
    h2.subline {
      margin-bottom: 2cm; }
  .text-primary {
    color: #000 !important; }
  .list-style-circle {
    list-style-type: circle;
    padding-left: .75cm; }
    .list-style-circle li {
      background: none;
      margin: .25cm 0;
      padding-left: 0; }
    .list-style-circle.primary li {
      background: none; }
  #nav-secondary .navbar-brand::after {
    content: ""; }
  .module-text {
    max-width: 20cm; }
    .module-text h2 {
      margin-top: 0 !important; } }

@page {
  margin: 2cm; }
